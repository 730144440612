
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { NextPage, NextPageContext } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import { getHomeListings } from '@unreserved-frontend-v2/api/fetchers/get-home-listings';
import { ssrPrefetch } from '@unreserved-frontend-v2/api/fetchers/ssr-prefetch';
import { getStandardCookiesFromCtx } from '@unreserved-frontend-v2/modules/users/utils/cookieHelpers';
import { getAuthHeaderFromCtx } from '@unreserved-frontend-v2/modules/users/utils/tokenHelpers';
import { ORIGIN_HOST } from '@unreserved-frontend-v2/utils/local-storage/constants';
import { isMobile, isMobileRequest } from '@unreserved-frontend-v2/utils/mobile';
import { BROWSE_BY_PLACE_DEFAULT_VARIABLES, DEFAULT_BROWSE_BY_REGION_SLUG, RECENTLY_LISTED_DESKTOP_VARIABLES, RECENTLY_LISTED_VARIABLES, RECENTLY_SOLD_DESKTOP_VARIABLES, RECENTLY_SOLD_VARIABLES, } from '../../components/pages/home/utils/constants';
const Mobile: React.ComponentType = dynamic(() => import('../../components/pages/home/mobile').then((mod) => mod.HomeMobile));
const Desktop: React.ComponentType = dynamic(() => import('../../components/pages/home/desktop').then((mod) => mod.HomeDesktop));
interface HomePageProps {
    isMobileOnServer?: boolean;
}
const HomePage: NextPage<HomePageProps> = ({ isMobileOnServer }: HomePageProps) => {
    const { t } = useTranslation();
    return (<>
      <Head>
        <title>{t('home:seo.title')}</title>
        <meta name="description" content={t('home:seo.description')}/>
        <link rel="canonical" href={ORIGIN_HOST}/>
      </Head>
      {isMobile(isMobileOnServer) ? <Mobile /> : <Desktop />}
    </>);
};
HomePage.getInitialProps = async (ctx: NextPageContext) => {
    const { req, query } = ctx;
    // Never return anything if we are running on the client.
    if (!req) {
        return {};
    }
    const isMobile = isMobileRequest(req);
    const parentPlaceSlug = (query['region'] as string) || DEFAULT_BROWSE_BY_REGION_SLUG;
    const variables = {
        recentlyListedInput: isMobile ? RECENTLY_LISTED_VARIABLES : RECENTLY_LISTED_DESKTOP_VARIABLES,
        recentlySoldInput: isMobile ? RECENTLY_SOLD_VARIABLES : RECENTLY_SOLD_DESKTOP_VARIABLES,
        browseByPlaceInput: {
            ...BROWSE_BY_PLACE_DEFAULT_VARIABLES,
            // only desktop allows region changing and uses parentPlaceSlug
            ...(!isMobile ? { parentPlaceSlug } : {})
        },
        withRegions: !isMobile
    };
    const { errorCode, dehydratedState } = await ssrPrefetch(['GetHomeListings', variables], getHomeListings(variables, getAuthHeaderFromCtx(ctx)));
    return {
        isSSR: true,
        isMobileOnServer: isMobile,
        errorCode,
        dehydratedState,
        // For SSR, the token must always be retrieved from the ctx and passed down to the UserContext
        cookies: getStandardCookiesFromCtx(ctx)
    };
};
const __Next_Translate__Page__1955d79d331__ = HomePage;

    export default __appWithI18n(__Next_Translate__Page__1955d79d331__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  